<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <div class="button_list">
                    <sn-button :snButton="snButton" @handleChange="handleChange"></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.saveJson"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="设备" prop="SBID">
                    <el-select
                        v-model="formdata.saveJson.SBID"
                        placeholder="请选择设备"
                        :disabled="true"
                    >
                        <el-option
                            v-for="(item,index) in sbList"
                            :key="`${item.ID}-${index}`"
                            :label="item.SBMC"
                            :value="item.ID"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="病害" prop="BHID">
                    <el-select
                        v-model="formdata.saveJson.BHID"
                        placeholder="请选择病害"
                        :disabled="true"
                    >
                        <el-option
                            v-for="(item,index) in bhList"
                            :key="`${item.ID}-${index}`"
                            :label="item.BHMC"
                            :value="item.ID"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="时间" prop="CJSJ">
                    <el-date-picker
                        v-model="formdata.saveJson.CJSJ"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :disabled="!isEdit"
                        placeholder="请选择时间"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="宽度" prop="JCZ">
                    <el-input
                        v-model="formdata.saveJson.JCZ"
                        placeholder="请输入宽度"
                        :maxlength="50"
                        onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    >
                        <template slot="append">{{formdata.saveJson.DW}}</template>
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import snButton from "../../../edit-button.js";
import infoMixin from "../../../info_mixin.js";
import { mapActions } from "vuex";
export default {
    name: "equipmentdata_crack_form",
    components: {},
    mixins: [infoMixin],
    data() {
        return {
            notNeedFile: true,
            snButton: snButton,
            // 根据承诺事项来源类型不同掉服务获取承诺事项来源列表
            cnwcsj: false,
            cnwcsj_date: "",
            formdata: {
                saveJson: {
                    SBID: "",
                    BHID: "",
                    CJSJ: "",
                    JCZ: 0,
                    RKSJ: ""
                },
                heritageId: "",
                itemID: "",
                userName: "",
                userId: ""
            },
            sbList: [], // 设备列表
            bhList: [] // 病害列表
        };
    },
    computed: {},
    watch: {
        "formdata.saveJson.cnsxlylx": function() {}
    },
    mounted() {
        this.getSbList();
        this.getBhList();
    },
    methods: {
        ...mapActions([
            "getSelectData",
            "DetailsDiseaseMonitorData", // 获取详情
            "SaveDiseaseMonitorData" // 保存
        ]),
        async getSbList() {
            this.sbList = [];
            let searchList = [];
            searchList = [
                {
                    FieldName: "SBLX",
                    FieldValue: "2",
                    OperatorChar: "="
                }
            ];
            let jsonStr = JSON.stringify(searchList);
            let res = await this.getSelectData({
                _cols: [], //字段集
                _keyword: null, //查询关键字
                _serarchCols: null, //关键字字段集合
                _conditionList: jsonStr, //过滤条件集合
                _dicOrderby: {},
                _pageSize: null, //每页最大记录数
                _pageIndex: null, //页索引
                _returnSum: false, //是否返回记录总数
                _needVersion: false, //是否分版本查询
                _searchFormal: false, //T：查询最近的正式版本数据；F：查询最近的数据
                itemid: "116" //设备业务编号
            });
            this.sbList = res.data;
        },
        async getBhList() {
            this.bhList = [];
            let res = await this.getSelectData({
                _cols: [], //字段集
                _keyword: null, //查询关键字
                _serarchCols: null, //关键字字段集合
                _conditionList: [], //过滤条件集合
                _dicOrderby: {},
                _pageSize: null, //每页最大记录数
                _pageIndex: null, //页索引
                _returnSum: false, //是否返回记录总数
                _needVersion: false, //是否分版本查询
                _searchFormal: false, //T：查询最近的正式版本数据；F：查询最近的数据
                itemid: "12" //病害业务编号
            });
            this.bhList = res.data;
        },
        // 获取详情
        async getFromData() {
            let params = {
                bhid: this.$route.query.bhid,
                cjsj: this.$route.query.cjsj,
                itemcode: "70304",
                bhlx: "60"
            };
            let result = await this.DetailsDiseaseMonitorData(params);
            if (result) {
                if (result.JCSJ && result.JCSJ.length > 0) {
                    if (result.JCSJ[0].JCX === "宽度") {
                        this.formdata.saveJson.JCZ = result.JCSJ[0].JCZ;
                    }
                }
                Object.assign(this.formdata.saveJson, result);
            }
        },
        // 保存
        async saveData() {
            let data = {
                ID: this.formdata.saveJson.ID,
                WZID: this.formdata.saveJson.WZID,
                SBID: this.formdata.saveJson.SBID,
                LRFS: this.formdata.saveJson.LRFS,
                JCSJ: [{ JCX: "宽度", JCZ: this.formdata.saveJson.JCZ }],
                DW: this.formdata.saveJson.DW,
                RWID: this.formdata.saveJson.RWID,
                bhid: this.formdata.saveJson.BHID,
                CJSJ: this.formdata.saveJson.CJSJ,
                RKSJ: this.formdata.saveJson.RKSJ
            };
            let res = await this.SaveDiseaseMonitorData({
                dataDic: data,
                itemCode: "70304",
                bhlx: "60"
            });
            if (res.IsSuccess) {
                this.$message({
                    type: "success",
                    message: "保存成功"
                });
                this.returnList();
            }
        }
    }
};
</script>